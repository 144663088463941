import styled from 'styled-components'

export const QandA = styled.ul`
  list-style: none;
  padding-left: 0;

  h4 {
    font-size: 18px;
    font-weight: bold;
  }
`;